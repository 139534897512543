import React, { Component } from "react";

class NotFound extends Component {
    

    render() {
        return (
            <div style={{
                textAlign: "center",
                margin: "auto",
                width: "50%",
                padding: 10,
                fontSize: 20
            }}

            >Page Not Found</div>
        )
    }
}

export default NotFound;