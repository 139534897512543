import React from "react"
import { Row, Col } from "reactstrap"
import PropTypes from 'prop-types'

const Footer = ({auth}) => {
  // console.log("auth", auth)
  return (
    <React.Fragment>
      <footer className={auth ? "auth-footer": "footer"}>
        <div className="container-fluid">
          <Row>
            <Col sm="4">{new Date().getFullYear()} © Atlantis.</Col>
            <Col sm="4" className="text-center">
              {/* <a href="https://support.atlantisgold.trade/hc/ja" rel="noreferrer" target="_blank">Support</a> */}
            </Col>
            <Col sm="4">
              <div className="text-sm-end d-none d-sm-block">
                All Rights Reserved
              </div>
            </Col>
          </Row>
        </div>
      </footer>
    </React.Fragment>
  )
}


export default Footer


Footer.propTypes = {
  auth: PropTypes.any
};
