import React, { Component } from "react";
import PropTypes from "prop-types"
import {
    Container,
    Col,
    Card,
    CardBody
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import BootstrapTable from "react-bootstrap-table-next"
import Breadcrumbs from "../../navigation/Breadcrumb"

import {
    getUsers,
    getBots,
    getReferralEarnings
} from "../../store/actions"

class UserDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            botColumns: [
                {
                    dataField: "name",
                    text: "Bot Name",
                    events: { onClick: this.onClickRow }
                },
                {
                    dataField: "pairs",
                    text: "Pairs",
                    events: { onClick: this.onClickRow },
                    formatter: (cellContent, row) => (
                        // this.props.exchangeAPIs?.find(api => api._id == row.api_id)?.exchange_id?.name
                        row.pairs.join(",")
                    )
                },
                {
                    dataField: "delta",
                    text: "Delta",
                    events: { onClick: this.onClickRow },
                    formatter: (cellContent, row) => (
                        (row.delta * 100) + '%'
                    )
                },
                {
                    dataField: "bp",
                    text: "Basis Points",
                    events: { onClick: this.onClickRow }
                },
                {
                    dataField: "aip",
                    text: "Return",
                    events: { onClick: this.onClickRow }
                },
                {
                    dataField: "updated_at",
                    text: "Created Date",
                    events: { onClick: this.onClickRow },
                    formatter: (cellContent, row) => (
                        row.updated_at ? (
                            new Date(row.updated_at)?.toLocaleString()
                        ) : (
                                new Date(row.created_date)?.toLocaleString()
                            )
                    )
                },
                {
                    dataField: "is_initialised",
                    text: "Initialized",
                    formatter: (cellContent, row) => (
                        row.is_initialised ? (
                            <i className="bx bx-check-circle font-size-24 text-success" />
                        ) : (
                                <i className="bx bx-x-circle font-size-24 text-danger" />

                            )
                    )
                },
                {
                    dataField: "subscription_expiry",
                    text: "Subscribed",
                    formatter: (cellContent, row) => (
                        row.subscription_expiry ? (
                            <i className="bx bx-check-circle font-size-24 text-success" />
                        ) : (
                                <i className="bx bx-x-circle font-size-24 text-danger" />

                            )
                    )
                },
                {
                    dataField: "status",
                    text: "Status",
                    events: { onClick: this.onClickRow },
                    formatter: (cellContent, row) => (
                        row.status ? (
                            <span className="text-success">Going</span>
                        ) : (
                                <span className="text-danger">Stopped</span>
                            )
                    )
                },
                {
                    dataField: "deleted",
                    text: "Deleted",
                    events: { onClick: this.onClickRow },
                    formatter: (cellContent, row) => (
                        <React.Fragment>
                            {row.deleted ? "Yes" : "No"} <br />
                            {row.deleted ? new Date(row.deleted_date)?.toLocaleString() : ""}
                        </React.Fragment>

                    )
                }
            ],
            referralEarningsColumns: [
                {
                    dataField: 'created_at',
                    text: 'Date',
                    formatter: (cellContent, row) => (
                        new Date(row?.created_at)?.toLocaleString()
                    )
                },
                {
                    dataField: 'amount',
                    text: 'Amount',
                    formatter: (cellContent, row) => (
                        row?.amount + ' PDT'
                    )
                },
                {
                    dataField: 'percentage',
                    text: 'Percentage',
                    formatter: (cellContent, row) => (
                        row?.percentage + '%'
                    )
                },
                {
                    dataField: 'refer_level',
                    text: 'Referral level',
                    formatter: (cellContent, row) => (
                        row?.refer_level
                    )
                },
                {
                    dataField: 'base_user_id',
                    text: 'Earned from',
                    formatter: (cellContent, row) => (
                        row?.base_user_id?.email
                    )
                }
            ],
        }

    }

    componentDidMount() {
        const { onGetUsers, onGetBots, onGetReferralEarnings } = this.props

        if (this.props.match.params.id) {
            onGetUsers({
                user_id: this.props.match.params.id
            })
            onGetBots({
                user_id: this.props.match.params.id
            })
            onGetReferralEarnings({
                user_id: this.props.match.params.id
            })
        }
    }

    onClickRow = (e, column, columnIndex, row, rowIndex) => {
        this.props.history.push('/botDetail/' + this.props.match.params.id + "/" + row._id);
    }

    render() {
        
        let bots = this.props.bots;
        let userDetail = this.props.userDetail[0];
        let referralEarnings = this.props.referralEarnings;
        // console.log("userDetail ", userDetail)
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Users List" to="#" breadcrumbItem="User Detail" parent="/userList" />
                        <Col xl="12">
                            {userDetail ?
                                <Card>
                                    <CardBody >
                                        <span>{userDetail?.name}</span>
                                        <br />

                                        <span>{userDetail?.email}
                                            <i className={`bx font-size-18 ${userDetail?.email_verified ? 'bx-check-circle text-success' : 'bx-x-circle text-danger'}`} />
                                        </span>
                                        <br />
                                        <span>2FA
                                            <i className={`bx font-size-18 ${userDetail?.two_fa_enable ? 'bx-check-circle text-success' : 'bx-x-circle text-danger'}`} />
                                        </span>
                                        <br />
                                        <span>
                                            PDT Balance: {userDetail?.balance}
                                        </span>

                                    </CardBody>
                                </Card>
                                : null
                            }
                            <Card>
                                <CardBody >
                                    <h5>User Bots</h5>
                                    {bots && bots?.length ? (
                                        <React.Fragment>
                                            <div className="table-responsive">
                                                <BootstrapTable
                                                    responsive
                                                    bordered={true}
                                                    striped={false}
                                                    data={bots}
                                                    columns={this.state.botColumns}
                                                    keyField="_id"
                                                    classes={
                                                        "table align-middle table-nowrap table-check bot-table"
                                                    }
                                                    headerWrapperClasses={"table-light"}
                                                />
                                            </div>
                                        </React.Fragment>
                                    ) : (
                                            <div>
                                                <p className="text-muted">You have not created any bots.</p>
                                            </div>
                                        )}
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody >
                                    <h5>Referral Earning</h5>
                                    {referralEarnings && referralEarnings?.length ? (
                                        <React.Fragment>
                                            <div className="table-responsive">
                                                <BootstrapTable
                                                    responsive
                                                    bordered={true}
                                                    striped={false}
                                                    data={referralEarnings}
                                                    columns={this.state.referralEarningsColumns}
                                                    keyField="_id"
                                                    classes={
                                                        "table align-middle table-nowrap table-check bot-table"
                                                    }
                                                    headerWrapperClasses={"table-light"}
                                                />
                                            </div>
                                        </React.Fragment>
                                    ) : (
                                            <div>
                                                <p className="text-muted">You have not created any bots.</p>
                                            </div>
                                        )}
                                </CardBody>
                            </Card>

                        </Col>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

UserDetail.propTypes = {
    isComponent: PropTypes.bool,
    t: PropTypes.any,
    userDetail: PropTypes.array,
    onGetUsers: PropTypes.func,
    bots: PropTypes.array,
    onGetBots: PropTypes.func,
    referralEarnings: PropTypes.array,
}

const mapStateToProps = state => ({
    userDetail: state.user.userDetail,
    bots: state.user.bots,
    referralEarnings: state.user.referralEarnings
})

const mapDispatchToProps = dispatch => ({
    onGetUsers: page => dispatch(getUsers(page)),
    onGetBots: page => dispatch(getBots(page)),
    onGetReferralEarnings: page => dispatch(getReferralEarnings(page)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(UserDetail)))