import { combineReducers } from "redux"

import auth from "./auth/reducer"
import user from "./user/reducer"
import pairs from "./pairs/reducer"

const rootReducer = combineReducers({
  auth,
  user,
  pairs,
})

export default rootReducer
