

export const getPairs = (page, onSuccess, onError) => ({
  type: 'GET_PAIRS',
  payload: page,
  onSuccess,
  onError
})

export const getPairsSuccess = pairs => ({
  type: 'GET_PAIRS_SUCCESS',
  payload: pairs,
})



export const showHidePair = (params, onSuccess, onError) => ({
  type: 'SHOW_HIDE_PAIR',
  payload: params,
  onSuccess,
  onError
})
