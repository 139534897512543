

const INIT_STATE = {
  pairs: [],
}

const Pairs = (state = INIT_STATE, action) => {
  switch (action.type) {

    case 'GET_PAIRS_SUCCESS':
      return {
        ...state,
        pairs: action.payload
      }

    default:
      return state
  }
}

export default Pairs
