

// Auth
import Login from "../pages/Auth/Login"

// Users
import UsersList from "../pages/Users/UsersList"
import UserDetail from "../pages/Users/UserDetail"
import BotDetail from "../pages/Users/BotDetail"
import Pairs from "../pages/Pairs/Pairs"


// Not Found
import NotFound from "../pages/NotFound"

// ====================



const authProtectedRoutes = [

  // Dashboard
  { path: "/usersList", component: UsersList },
  { path: "/userDetail/:id", component: UserDetail },
  { path: "/botDetail/:id/:bot_id", component: BotDetail },
  { path: "/pairs", component: Pairs },


]

const publicRoutes = [

  { path: '/login', component: Login },
  { path: '/notFound', component: NotFound },

]

export { authProtectedRoutes, publicRoutes }
