import React, { Component } from "react";
import PropTypes from "prop-types"
import {
    Container,
    Col,
    Row,
    Card,
    CardBody
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import BootstrapTable from "react-bootstrap-table-next"
import Breadcrumbs from "../../navigation/Breadcrumb"

import {
    getUsers,
    getBots
} from "../../store/actions"

class BotDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            invoicesColumns: [
                {
                    dataField: "invoice",
                    text: "Invoice id",
                    events: { onClick: this.onClickRow }
                },
                {
                    dataField: "paid_coin_amount",
                    text: "Amount",
                    events: { onClick: this.onClickRow }
                },
                {
                    text: "Type",
                    events: { onClick: this.onClickRow },
                    formatter: (cellContent, row) => (
                        row.initial_payment ? "Initial Payment" : "Subscription"
                    )
                },
                {
                    dataField: "created_at",
                    text: "Created Date",
                    events: { onClick: this.onClickRow },
                    formatter: (cellContent, row) => (
                        new Date(row.created_at)?.toLocaleString()

                    )
                }
            ],
        }

    }

    componentDidMount() {
        const { onGetUsers, onGetBots } = this.props
        if (this.props.match.params.id) {
            onGetUsers({
                user_id: this.props.match.params.id
            })
        }
        if (this.props.match.params.bot_id) {
            onGetBots({
                bot_id: this.props.match.params.bot_id
            })
        }
    }

    onClickRow = (e, column, columnIndex, row, rowIndex) => {
        // this.props.history.push('/bot-detail/' + row._id);
    }

    render() {
        let userDetail = this.props.userDetail[0];
        let botDetail = this.props.botDetail[0];
        let invoices = this.props.invoices;
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Users Detail" to="#" breadcrumbItem="Bot Detail" parent={`/userDetail/${this.props.match.params.id}`} />
                        <Row>
                            <Col sm="6">
                                <Card>
                                    <CardBody >
                                        <strong>User Details</strong>
                                        {userDetail ?
                                            <div>
                                                <span>{userDetail?.name}</span>
                                                <br />

                                                <span>{userDetail?.email}
                                                    <i className={`bx font-size-18 ${userDetail?.email_verified ? 'bx-check-circle text-success' : 'bx-x-circle text-danger'}`} />
                                                </span>
                                                <br />
                                                <span>2FA
                                            <i className={`bx font-size-18 ${userDetail?.two_fa_enable ? 'bx-check-circle text-success' : 'bx-x-circle text-danger'}`} />
                                                </span>
                                                <br />
                                                <span>
                                                    PDT Balance: {userDetail?.balance}
                                                </span>
                                            </div>

                                            : null
                                        }

                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm="6">
                                <Card>
                                    <CardBody >
                                        <strong>Bot Detail</strong>
                                        {botDetail ?
                                            <div>
                                                <span>Bot Name: {botDetail?.name}</span>
                                                <br />

                                                <span>
                                                    Bot Status:
                                                    {
                                                        botDetail.status ? (
                                                            <span className="text-success"> Going</span>
                                                        ) : (
                                                                <span className="text-danger"> Stopped</span>
                                                            )
                                                    }
                                                </span>
                                                <br />
                                                <span>
                                                    Initialised
                                                    {
                                                        botDetail.is_initialised ? (
                                                            <i className="bx bx-check-circle font-size-18 text-success" />
                                                        ) : (
                                                                <i className="bx bx-x-circle font-size-18 text-danger" />

                                                            )
                                                    }
                                                </span>
                                                <br />
                                                <span>
                                                    Subscribed
                                                    {
                                                        botDetail.subscription_expiry ? (
                                                            <i className="bx bx-check-circle font-size-18 text-success" />
                                                        ) : (
                                                                <i className="bx bx-x-circle font-size-18 text-danger" />

                                                            )
                                                    }
                                                </span>
                                            </div>

                                            : null
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Col xl="12">
                            
                            <Card>
                                <CardBody >
                                    <h5>Bot Invoices</h5>
                                    {invoices && invoices?.length ? (
                                        <React.Fragment>
                                            <div className="table-responsive">
                                                <BootstrapTable
                                                    responsive
                                                    bordered={true}
                                                    striped={false}
                                                    data={invoices}
                                                    columns={this.state.invoicesColumns}
                                                    keyField="_id"
                                                    classes={
                                                        "table align-middle table-nowrap table-check bot-table"
                                                    }
                                                    headerWrapperClasses={"table-light"}
                                                />
                                            </div>
                                        </React.Fragment>
                                    ) : (
                                            <div>
                                                <p className="text-muted">You have no invoices.</p>
                                            </div>
                                        )}
                                </CardBody>
                            </Card>

                        </Col>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

BotDetail.propTypes = {
    isComponent: PropTypes.bool,
    t: PropTypes.any,
    userDetail: PropTypes.array,
    onGetUsers: PropTypes.func,
    botDetail: PropTypes.array,
    onGetBots: PropTypes.func,
    invoices: PropTypes.array,
}

const mapStateToProps = state => ({
    userDetail: state.user.userDetail,
    botDetail: state.user.botDetail,
    invoices: state.user.invoices,

})

const mapDispatchToProps = dispatch => ({
    onGetUsers: page => dispatch(getUsers(page)),
    onGetBots: page => dispatch(getBots(page)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(BotDetail)))