export const getUsers = (page, onSuccess, onError) => ({
  type: 'GET_USERS',
  payload: page,
  onSuccess,
  onError
})

export const getUsersSuccess = users => ({
  type: 'GET_USERS_SUCCESS',
  payload: users,
})

export const getUsersFail = error => ({
  type: 'GET_USERS_FAIL',
  payload: error,
})

export const getBots = (page, onSuccess, onError) => ({
  type: 'GET_BOTS',
  payload: page,
  onSuccess,
  onError
})

export const getBotsSuccess = orders => ({
  type: 'GET_BOTS_SUCCESS',
  payload: orders,
})

export const getBotsFail = error => ({
  type: 'GET_BOTS_FAIL',
  payload: error,
})

export const getReferralEarnings = (page, onSuccess, onError) => ({
  type: 'GET_REFERRAL_EARNINGS',
  payload: page,
  onSuccess,
  onError
})

export const getReferralEarningsSuccess = orders => ({
  type: 'GET_REFERRAL_EARNINGS_SUCCESS',
  payload: orders,
})

export const getReferralEarningsFail = error => ({
  type: 'GET_REFERRAL_EARNINGS_FAIL',
  payload: error,
})