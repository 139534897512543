

const INIT_STATE = {
  users: [],
  userDetail: [],
  bots: [],
  botDetail: [],
  invoices: [],
  error: {},
  referralEarnings: []
}

const Users = (state = INIT_STATE, action) => {
  switch (action.type) {

    case 'GET_USERS_SUCCESS':
      if (action.payload.length === 1) {
        return {
          ...state,
          userDetail: action.payload,
        }
      } else {
        return {
          ...state,
          users: action.payload
        }
      }


    case 'GET_USERS_FAIL':
      return {
        ...state,
        error: action.payload,
      }

    case 'GET_BOTS_SUCCESS':
      if (action.payload.data.length === 1) {
        return {
          ...state,
          botDetail: action.payload.data,
          bots: action.payload.data,
          invoices: action.payload.Invoices,
        }
      } else {
        return {
          ...state,
          bots: action.payload.data,
        }
      }

    case 'GET_BOTS_FAIL':
      return {
        ...state,
        error: action.payload,
      }

    case 'GET_REFERRAL_EARNINGS_SUCCESS':
        return {
          ...state,
          referralEarnings: action.payload,
        }
        

    case 'GET_REFERRAL_EARNINGS_FAIL':
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Users
