
import { call, put, takeEvery } from "redux-saga/effects"

import {
  getPairsSuccess,
} from "./actions"

import { post } from "../../helpers/api_helper"



function* fetchPairs({ payload: page, onSuccess, onError }) {
  try {
    const response = yield call(post, 'admin/pairs', page)
    if (onSuccess) onSuccess(response)
    yield put(getPairsSuccess(response.data))
  } catch (error) {
    if (onError) onError(error.response)
    yield console.error(error.response)
  }
}



function* showHidePair({ payload: params, onSuccess, onError }) {
  try {
    const response = yield call(post, 'admin/showOrHide', params)
    if (onSuccess) onSuccess(response)
  } catch (error) {
    if (onError) onError(error.response)
  }
}



function* pairsSaga() {
  yield takeEvery('GET_PAIRS', fetchPairs)
  yield takeEvery('SHOW_HIDE_PAIR', showHidePair)
}

export default pairsSaga
