import React, { Component } from "react"
import PropTypes from "prop-types"
import {
    Card,
    CardBody,
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import BootstrapTable from "react-bootstrap-table-next"
import paginator from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

import Breadcrumbs from "../../navigation/Breadcrumb"

import {
    getUsers,
} from "../../store/actions"



class UsersList extends Component {
    constructor(props) {
        // console.log("UsersList constructor");
        super(props);

        this.state = {
            
            botColumns: [
                {
                    dataField: "name",
                    text: "Name ",
                    events: { onClick: this.onClickRow },
                    filter: textFilter()
                },
                {
                    dataField: "email",
                    text: "Email ",
                    events: { onClick: this.onClickRow },
                    filter: textFilter(),
                    formatter: (cellContent, row) => (
                        <React.Fragment>

                            {row.email} {" "}
                            {row.email_verified ? (
                                <i className="bx bx-check-circle font-size-18 text-success" />
                            ) : (
                                    <i className="bx bx-x-circle font-size-18 text-danger" />
                                )}
                        </React.Fragment>
                    )

                },
                {
                    dataField: "is_active",
                    text: "Active ",
                    events: { onClick: this.onClickRow },
                    filter: textFilter()

                },
                {
                    dataField: "role",
                    text: "Role ",
                    events: { onClick: this.onClickRow },
                    filter: textFilter()
                },
                {
                    dataField: "two_fa_enable",
                    text: "2FA ",
                    events: { onClick: this.onClickRow },
                    filter: textFilter()
                },
                {
                    dataField: "created_at",
                    text: "Created Date ",
                    events: { onClick: this.onClickRow },
                    formatter: (cellContent, row) => (
                        new Date(row.created_at)?.toLocaleString()
                    )
                },

            ],
        }
    }

    componentDidMount() {
        const { users, onGetUsers } = this.props

        if (users && !users.length) {
            onGetUsers()
        }
    }

    onClickRow = (e, column, columnIndex, row, rowIndex) => {
        this.props.history.push('/userDetail/' + row._id, { userDetails: row });
    }


    render() {
        // console.log("this.props ", this.props);
        const { users, isComponent } = this.props

        return (
            <React.Fragment>
                <div className={isComponent ? '' : 'page-content'}>

                    {!isComponent && <Breadcrumbs title={this.props.t("Users")} />}

                    <Card>
                        <CardBody>
                            <div className="mb-4 h4 card-title" style={{ justifyContent: "space-between", display: "flex", flexDirection: "row" }}>
                                <p>{this.props.t("Users")}</p>
                            </div>


                            {users && users?.length ? (
                                <React.Fragment>
                                    <div className="table-responsive">
                                        <BootstrapTable
                                            responsive
                                            bordered={true}
                                            striped={false}
                                            data={users}
                                            columns={this.state.botColumns}
                                            pagination={paginator()}
                                            filter={filterFactory()}
                                            keyField="_id"
                                            classes={
                                                "table align-middle table-check bot-table"
                                            }
                                            headerWrapperClasses={"table-light"}
                                        />
                                    </div>
                                </React.Fragment>
                            ) : (
                                    <div>
                                        <p className="text-muted">There is no user.</p>
                                    </div>
                                )}
                        </CardBody>
                    </Card>
                </div>
            </React.Fragment>

        )
    }
}

UsersList.propTypes = {
    isComponent: PropTypes.bool,
    t: PropTypes.any,
    users: PropTypes.array,
    onGetUsers: PropTypes.func,
}

const mapStateToProps = state => ({
    users: state.user.users
})

const mapDispatchToProps = dispatch => ({
    onGetUsers: (page) => dispatch(getUsers(page)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(UsersList)))
