

export const getMe = (onSuccess, onError) => ({
  type: 'GET_ME',
  onSuccess,
  onError
})

export const getMeSuccess = user => ({
  type: 'GET_ME_SUCCESS',
  payload: user,
})

export const getMeFail = error => ({
  type: 'GET_ME_FAIL',
  payload: error,
})


export const login = (auth, onSuccess, onError) => ({
  type: 'LOGIN',
  payload: auth,
  onSuccess,
  onError
})

export const loginSuccess = data => ({
  type: 'LOGIN_SUCCESS',
  payload: data,
})

export const loginFail = error => ({
  type: 'LOGIN_FAIL',
  payload: error,
})



export const login2fa = (auth, onSuccess, onError) => ({
  type: 'LOGIN_2FA',
  payload: auth,
  onSuccess,
  onError
})

export const login2faSuccess = data => ({
  type: 'LOGIN_2FA_SUCCESS',
  payload: data,
})


export const logout = () => ({
  type: 'LOGOUT'
})
