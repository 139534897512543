import React, {Component} from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Card,
  CardBody,
  Modal
} from "reactstrap";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import BootstrapTable from "react-bootstrap-table-next";
import paginator from "react-bootstrap-table2-paginator";
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";

import Breadcrumbs from "../../navigation/Breadcrumb";

import {
  getPairs,
  showHidePair,
} from "../../store/actions";

class Pairs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showHideModalVisible: false,
      selectedPair: null,
      success: null,
      error: null,

      botColumns: [
        {
          dataField: "pair",
          text: <p className="mb-0">Pair</p>,
          events: {
            onClick: this.onClickRow
          },
          filter: textFilter({ placeholder: 'Filter by Pair...' })
        },
        {
          dataField: "_id",
          text: <p className="mb-0">ID</p>,
          events: {
            onClick: this.onClickRow
          },
          filter: textFilter({ placeholder: 'Filter by ID...' })
        },
        {
          dataField: "created_date",
          text: <p className="mb-0">Date</p>,
          events: {
            onClick: this.onClickRow
          },
          formatter: (cellContent, row) => {
            return new Date(row.created_date)?.toLocaleString()
          },
          filter: textFilter({ placeholder: 'Filter by Date...' })
        },
        {
          dataField: "status",
          text: <p className="mb-0">Status</p>,
          events: {
            onClick: this.onClickRow
          },
          formatter: (cellContent, row) => {
            return row.status ? (
              <div className="d-flex align-items-center">
                <i className="bx bx-check-circle font-size-18 text-success" />
                <p className="mb-0 ms-2 text-success">Visible</p>
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <i className="bx bx-x-circle font-size-18 text-danger" />
                <p className="mb-0 ms-2 text-danger">Not Visible</p>
              </div>
            )
          },
          filter: textFilter({ placeholder: 'Filter by Status...' })
        },
        {
          dataField: "action",
          text: <p className="mb-3">Actions</p>,
          formatter: (cellContent, row) => {
            return row.status ? (
              <button
                className="btn btn-danger"
                onClick={() => this.clickShowHidePair(row)}
              >Hide</button>
            ) : (
              <button
                className="btn btn-success"
                onClick={() => this.clickShowHidePair(row)}
              >Show</button>
            )
          }
        },
      ]
    }

    this.confirmShowHidePairModal = this.confirmShowHidePairModal.bind(this)

    this.toggleConfirmShowHideModal = this.toggleConfirmShowHideModal.bind(this)
    this.clickShowHidePair = this.clickShowHidePair.bind(this)
    this.showHidePair = this.showHidePair.bind(this)

    this.showHidePairSuccess = this.showHidePairSuccess.bind(this)
    this.showHidePairError = this.showHidePairError.bind(this)
  }

  componentDidMount() {
    this.props.onGetPairs()
  }

  onClickRow = (e, column, columnIndex, row, rowIndex) => {
  }

  toggleConfirmShowHideModal() {
    this.setState({ showHideModalVisible: !this.state.showHideModalVisible })
  }

  clickShowHidePair(pair) {
    this.setState({
      selectedPair: pair,
      showHideModalVisible: true
    })
  }

  showHidePair() {
    this.props.onShowHidePair({
      _id: this.state.selectedPair?._id
    }, this.showHidePairSuccess, this.showHidePairError)
  }

  showHidePairSuccess(response) {
    this.setState({
      success: response?.data?.message,
      error: null,
      selectedPair: null,
      showHideModalVisible: false
    })
    this.props.onGetPairs()
  }

  showHidePairError(response) {
    this.setState({
      error: response.status == 404 ? '404 Not Found' : response?.error,
      success: null,
      selectedPair: null,
      showHideModalVisible: false
    })
  }


  confirmShowHidePairModal() {
    return (
      <Modal
        isOpen={this.state.showHideModalVisible}
        toggle={this.toggleConfirmShowHideModal}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">{this.props.t("Withdraw Funds")}</h5>
          <button
            type="button"
            onClick={this.toggleConfirmShowHideModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <p>{this.state.selectedPair?.status ? 'Hide' : 'Show'} <span className="text-primary fw-bold">{this.state.selectedPair?.pair}</span>?</p>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            onClick={this.toggleConfirmShowHideModal}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            {this.props.t("Close")}
          </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={this.showHidePair}
          >
            {this.props.t("Confirm")}
          </button>
        </div>
      </Modal>
    )
  }


  render() {
    const { isComponent } = this.props;

    return (
      <React.Fragment>
        <div className={isComponent ? "" : "page-content"}>
          {!isComponent && <Breadcrumbs title={this.props.t("Users")} />}

          {this.confirmShowHidePairModal()}

          <Card>
            <CardBody>
              <div
                className="mb-4 h4 card-title"
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                  flexDirection: "row"
                }}
              >
                <p>{this.props.t("Coin Pairs")}</p>
              </div>

              {this.state.success && (
                <Alert color="success" role="alert" className="mt-3">
                  {this.state.success}
                </Alert>
              )}

              {this.state.error && (
                <Alert color="danger" role="alert" className="mt-3">
                  {this.state.error}
                </Alert>
              )}

              {this.props.pairs?.length ? (
                <React.Fragment>
                  <div className="table-responsive">
                    <BootstrapTable
                      responsive
                      bordered={true}
                      striped={false}
                      data={this.props.pairs}
                      columns={this.state.botColumns}
                      pagination={paginator()}
                      filter={filterFactory()}
                      keyField="_id"
                      classes={"table align-middle table-check bot-table"}
                      headerWrapperClasses={"table-light"}
                    />
                  </div>
                </React.Fragment>
              ) : (
                <div>
                  <p className="text-muted">There are no coin pairs.</p>
                </div>
              )}
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

Pairs.propTypes = {
  isComponent: PropTypes.bool,
  t: PropTypes.any,
  pairs: PropTypes.any,
  onGetPairs: PropTypes.func,
  onShowHidePair: PropTypes.func,
};

const mapStateToProps = state => ({
  pairs: state.pairs.pairs
});

const mapDispatchToProps = dispatch => ({
  onGetPairs: page => dispatch(getPairs(page)),
  onShowHidePair: (params, onSuccess, onError) => dispatch(showHidePair(params, onSuccess, onError))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Pairs)));
