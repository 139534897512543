import { call, put, takeEvery } from "redux-saga/effects"

import {
  getMeSuccess,
  getMeFail,
} from "./actions"

import { get, post, setIP } from "../../helpers/api_helper"


function* getMe({ onSuccess, onError }) {
  try {
    const response = yield call(get, 'users/me')
    if (onSuccess) onSuccess(response)
    yield put(getMeSuccess(response))
  } catch (error) {
    if (onError) onError(error?.response);
    yield put(getMeFail(error?.response))
  }
}


function* login({ payload: auth, onSuccess, onError }) {
  try {
    setIP(auth.ip_address)
    const response = yield call(post, 'users/auth', auth)
    if (onSuccess) onSuccess(response)
  } catch (error) {
    if (onError) onError(error?.response)
  }
}


function* login2fa({ payload: auth, onSuccess, onError }) {
  try {
    const response = yield call(post, 'users/verify2faLogin', auth)
    if (onSuccess) onSuccess(response)
  } catch (error) {
    if (onError) onError(error?.response)
  }
}


function* logout({ payload: page }) {
  try {
    yield console.log('');
    // yield put(logout(response.data))
  } catch (error) {
    console.error(error)
  }
}


function* botsSaga() {
  yield takeEvery('GET_ME', getMe)
  yield takeEvery('LOGIN', login)
  yield takeEvery('LOGIN_2FA', login2fa)
  yield takeEvery('LOGOUT', logout)
}

export default botsSaga
