import React, { Component } from "react"
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom"

import { authProtectedRoutes, publicRoutes } from "./routes/"
import AppRoute from "./routes/route"

import Navigation from "./navigation/Navigation"

import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"

import "./assets/scss/theme.scss"

// import fakeBackend from "./helpers/AuthType/fakeBackend"
// fakeBackend()

import { getMe } from "./store/actions"



class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userFetched: false,
    }

    this.onGetMeSuccess = this.onGetMeSuccess.bind(this)
    this.onGetMeError = this.onGetMeError.bind(this)

    if (window.localStorage.getItem('token')) {
      this.props.onGetMe(this.onGetMeSuccess, this.onGetMeError)
    }
  }

  onGetMeSuccess() {
    setTimeout(() => {
      this.setState({ userFetched: true })
    }, 2000);
  }

  onGetMeError(response) {
    setTimeout(() => {
      this.setState({ userFetched: true })
    }, 2000);

    if (response?.status == 401) { // eslint-disable-line
      // setTimeout(() => {
      //   this.props.history.push('/login')
      // }, 2000);
    }
  }



  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={null}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
              />
            ))}

            {this.state.userFetched && !this.props.user ? (
              <Redirect to="/login" />
            ) : ''}

            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={Navigation}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}
            <Redirect to="/usersList" />
          </Switch>
        </Router>
      </React.Fragment>
    )
  }
}

App.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  user: PropTypes.object,
  onGetMe: PropTypes.func,
}

const mapStateToProps = state => ({
  user: state.auth.user,
})

const mapDispatchToProps = dispatch => ({
  onGetMe: (onSuccess, onError) => dispatch(getMe(onSuccess, onError)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(App)))
