

import { setToken } from "../../helpers/api_helper"


const INIT_STATE = {
  user: null
}

const Auth = (state = INIT_STATE, action) => {
  switch (action.type) {

    case 'GET_ME_SUCCESS':
      return {
        ...state,
        user: {
          ...action.payload?.data,
          address: action.payload?.address
        },
      }

    case 'GET_ME_FAIL':
      return {
        ...state,
        error: action.payload,
      }

    case 'LOGIN':
      return {
        ...state,
        user: action.payload,
      }

    case 'LOGIN_SUCCESS':
      console.log(action.payload);
      window.localStorage.setItem('token', action.payload?.data?.token)
      setToken(action.payload?.data?.token)
      return {
        ...state,
        user: {
          ...action.payload?.data?.user,
          address: action.payload?.data?.address
        },
      }

    case 'LOGIN_FAIL':
      return {
        ...state,
        error: action.payload,
      }



    case 'LOGOUT':
      return {
        ...state,
        error: action.payload,
      }


    case 'TOGGLE_2FA_SUCCESS':
      return {
        ...state,
        user: {
          ...state.user,
          two_fa_enable: !state.user?.two_fa_enable
        }
      }


    default:
      return state
  }
}

export default Auth
