import React, { Component } from "react"
import PropTypes from 'prop-types'
import { withRouter } from "react-router-dom"

import Header from "./Header"
import Sidebar from "./Sidebar"
import Footer from "./Footer"


class Navigation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
    }
  }

  componentDidMount() {
    // document.body.setAttribute('data-topbar', 'dark');
    document.body.setAttribute('data-sidebar', 'dark');

    if (this.props.isPreloader === true) {
      document.getElementById("preloader").style.display = "block"
      document.getElementById("status").style.display = "block"

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none"
        document.getElementById("status").style.display = "none"
      }, 2500)
    } else {
      document.getElementById("preloader").style.display = "none"
      document.getElementById("status").style.display = "none"
    }

    window.scrollTo(0, 0)
  }


  render() {
    return (
      <React.Fragment>
        <div id="preloader">
          <div id="status">
            <div className="spinner-chase">
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
            </div>
          </div>
        </div>

        <div id="layout-wrapper">
          <Header />
          <Sidebar
            theme="dark"
            type="default"
            isMobile={this.state.isMobile}
          />
          <div className="main-content">{this.props.children}</div>
          <Footer auth={false}/>
        </div>
      </React.Fragment>
    )
  }
}

Navigation.propTypes = {
  children: PropTypes.any,
  isPreloader: PropTypes.bool,
  location: PropTypes.object,
  topbarTheme: PropTypes.any
}

export default withRouter(Navigation)
