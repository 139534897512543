import React, { Component } from "react"
import PropTypes from 'prop-types'

import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Input
} from "reactstrap"

import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"

import { AvField, AvForm } from "availity-reactstrap-validation"

import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/atlantis_logo_blue.png"

import {
  login,
  loginSuccess,
  login2fa
} from "../../store/actions"


import Footer from './../../navigation/Footer';

import ReCaptcha from 'react-google-recaptcha';
import { recaptchaKey } from '../../helpers/api_helper';

import axios from 'axios';

let loginResponse;


class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      success: null,
      error: null,

      verify2faVisible: false,
      code2fa: null,
      error2fa: null,

      email: '',
      password: '',
      recaptcha: '',
      ip: ''
    }

    this.onRecaptchaChange = this.onRecaptchaChange.bind(this)

    this.onClickLogin = this.onClickLogin.bind(this)
    this.onLoginSuccess = this.onLoginSuccess.bind(this)
    this.onLoginError = this.onLoginError.bind(this)
    this.onCheckLoginSuccess = this.onCheckLoginSuccess.bind(this)
    this.onCheckLoginError = this.onCheckLoginError.bind(this)
    this.verify2FA = this.verify2FA.bind(this)
    this.onVerify2FASuccess = this.onVerify2FASuccess.bind(this)
    this.onVerify2FAError = this.onVerify2FAError.bind(this)
    this.toggle2faModal = this.toggle2faModal.bind(this)
    this.redirectToDashboard = this.redirectToDashboard.bind(this)
  }

  componentDidMount() {
    axios.get('https://ipapi.co/json/').then(response => {
      // console.log('got ip:', response.data.ip);
      this.setState({
        ip: response.data.ip
      })
    });
  }

  onRecaptchaChange(value) {
    this.setState({ recaptcha: value })
  }

  onClickLogin(event, values) {
    this.setState({
      loading: true,
      email: values.email,
      password: values.password,
      ip_address: this.state.ip
    })

    const auth = {
      email: values.email,
      password: values.password,
      recaptcha_response: this.state.recaptcha,
      ip_address: this.state.ip
    }
    this.props.onLogin(auth, this.onCheckLoginSuccess, this.onCheckLoginError)
  }

  onCheckLoginSuccess(response) {
    let has2fa = response?.user?.two_fa_enable
    if (has2fa) {
      loginResponse = response
      this.setState({
        loading: false,
        verify2faVisible: true
      })
    }
    else this.onLoginSuccess(response)
  }

  onCheckLoginError(response) {
    this.setState({
      loading: false,
      error: response?.data?.msg
    });
  }

  verify2FA() {
    this.setState({ loading: true })
    let params = {
      email: this.state.email,
      password: this.state.password,
      token_code: this.state.code2fa
    }
    this.props.onLogin2FA(params, this.onVerify2FASuccess, this.onVerify2FAError)
  }

  onVerify2FASuccess(response) {
    loginResponse.data.token = response?.data?.token
    this.onLoginSuccess(loginResponse);
  }

  onVerify2FAError(response) {
    this.setState({
      loading: false,
      error2fa: response?.data?.msg
    });
  }

  onLoginSuccess(response) {
    this.props.onLoginSuccess(response)
    this.setState({
      loading: false,
      verify2faVisible: false,
      success: 'Login successful.'
    })
    setTimeout(this.redirectToDashboard, 1000);
  }

  onLoginError(response) {
    this.setState({
      loading: false,
      error: response.data?.msg
    });
  }

  redirectToDashboard() {
    this.props.history.push('/usersList')
  }


  toggle2faModal() {
    this.setState({ verify2faVisible: !this.state.verify2faVisible })
  }

  twoFAModal() {
    return (
      <Modal
        isOpen={this.state.verify2faVisible}
        toggle={this.toggle2faModal}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">Two Factor Authentication</h5>
          <button
            type="button"
            onClick={this.toggle2faModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">

          <p>Please enter your Google Authenticator code below to log in.</p>

          <Input
            type="number"
            className="form-control"
            onInput={(e) => this.setState({ code2fa: e.target.value })}
          />

          {this.state.error2fa && (
            <Alert color="danger" role="alert" className="mt-3">
              {this.state.error2fa}
            </Alert>
          )}

        </div>

        <div className="modal-footer">
          <button
            type="button"
            onClick={this.toggle2faModal}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
            </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={this.verify2FA}
            disabled={!this.state.code2fa}
          >
            {this.state.loading ? (
              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
            ) : 'Login'}
          </button>
        </div>
      </Modal>
    )
  }

  render() {
    return (
      // <React.Fragment>
      <div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>

            {this.twoFAModal()}

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>

                <div style={{ width: '180px', margin: 'auto', marginBottom: '2rem' }}>
                  <img src={logo} width="100%" alt="logo" />
                </div>

                <Card className="overflow-hidden">

                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Welcome to Atlantis Admin!</h5>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>

                  <CardBody className="pt-0">

                    {/*
                    <div className="auth-logo">
                      <Link to="/" className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={lightlogo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                      <Link to="/" className="auth-logo-dark">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    */}

                    <div className="p-3 pt-5">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={this.onClickLogin}
                      >
                        {this.state.success && (
                          <Alert color="success" role="alert">
                            {this.state.success}
                          </Alert>
                        )}

                        {this.state.error && (
                          <Alert color="danger" role="alert">
                            {this.state.error}
                          </Alert>
                        )}

                        <div className="mb-3">
                          <AvField
                            required
                            type="email"
                            name="email"
                            label="Email"
                            value={this.state.email}
                            className="form-control"
                            placeholder="Email"
                            onChange={(e) => this.setState({ email: e.target.value })}
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            required
                            type="password"
                            name="password"
                            label="Password"
                            value={this.state.password}
                            placeholder="Password"
                            onChange={(e) => this.setState({ password: e.target.value })}
                          />
                        </div>

                        {/*
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                            defaultChecked
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            Remember me
                          </label>
                        </div>
                        */}

                        <ReCaptcha
                          sitekey={recaptchaKey}
                          onChange={this.onRecaptchaChange}
                        />

                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            {this.state.loading ? (
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
                            ) : 'Login'}
                          </button>
                        </div>

                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Footer auth={true} />
          </Container>
        </div>
      </div>
      // </React.Fragment>
    )
  }
}

Login.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  user: PropTypes.any,
  error: PropTypes.any,
  onLogin: PropTypes.func,
  onLoginSuccess: PropTypes.func,
  onLogin2FA: PropTypes.func,
}

const mapStateToProps = state => ({
  user: state.auth.user,
  error: state.error
})

const mapDispatchToProps = dispatch => ({
  onLogin: (auth, onSuccess, onError) => dispatch(login(auth, onSuccess, onError)),
  onLoginSuccess: (data) => dispatch(loginSuccess(data)),
  onLogin2FA: (code, onSuccess, onError) => dispatch(login2fa(code, onSuccess, onError)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Login)))
