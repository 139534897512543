import { call, put, takeEvery } from "redux-saga/effects"

import {
  getUsersSuccess,
  getUsersFail,
  getBotsSuccess,
  getBotsFail,
  getReferralEarningsSuccess,
  getReferralEarningsFail
} from "./actions"

import { post } from "../../helpers/api_helper"


function* fetchUsers({ payload: page, onSuccess, onError }) {
  try {
    const response = yield call(post, 'admin/users', page)
    if (onSuccess) onSuccess(response)
    yield put(getUsersSuccess(response.data))
  } catch (error) {
    if (onError) onError(error.response)
    yield put(getUsersFail(error))
  }
}

function* fetchBots({ payload: page, onSuccess, onError }) {
  try {
    const response = yield call(post, 'admin/getBot', page)
    if (onSuccess) onSuccess(response)
    yield put(getBotsSuccess(response))
  } catch (error) {
    if (onError) onError(error.response)
    yield put(getBotsFail(error))
  }
}
function* fetchReferralEarnings({ payload: page, onSuccess, onError }) {
  try {
    const response = yield call(post, 'admin/referral', page)
    if (onSuccess) onSuccess(response)
    yield put(getReferralEarningsSuccess(response.data))
  } catch (error) {
    if (onError) onError(error.response)
    yield put(getReferralEarningsFail(error))
  }
}



function* usersSaga() {
  yield takeEvery('GET_USERS', fetchUsers)
  yield takeEvery('GET_BOTS', fetchBots)
  yield takeEvery('GET_REFERRAL_EARNINGS', fetchReferralEarnings)
}

export default usersSaga
